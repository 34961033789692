.headerButtonWrapper {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -10px;
  margin-bottom: -18px;
}

.description {
  width: 90%;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 13px
  }
}

.antddescription {
  * {
    width: 90%
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #00A0C6;
  text-decoration: none !important;
  cursor: pointer;
}

.checkBox {
  label {
    background-color: #fafafa;
    width: 48%;
  }
}

.headerRight {
  align-self: flex-end;
}

.mainLeadView {
  height: ~"calc(100vh - 112px)";
  // overflow-y: scroll;
  overflow: hidden;
}

.cardCont {
  height: ~"calc(100vh - 112px)";
  overflow-y: scroll;
  overflow-x: hidden;
  // text-align: center;
  .loadMore {
    text-align: center;
    margin: 5px !important;
  }

  .card {
    background-color: #f4f4f4;
    margin-bottom: 0px;
    border-radius: 0px;
    padding: 10px;

    border: 1px #d3d3d3 solid;
    border-bottom: 2px #d3d3d3 solid;

    .title {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      border-bottom: 1px #e4e4e4 solid;
      padding-bottom: 5px;
    }

    .subtitle {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .info {
      font-size: 14px;

      p {
        margin: 0;
        display: inline-block;
      }

      .budgetName {
        margin-bottom: 3px;
        margin-right: 10px;
        padding: 1px 5px;
        font-size: 12px;
        font-weight: 700;
        background-color: lighten(@primary-color, 40%);
        border: 1px lighten(@primary-color, 25%) solid;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      margin-top: 10px;
      // color: #c2bcbc;
      color: #777E8B;

      i {
        cursor: pointer;
        margin: 0 5px;
      }

      i:hover {
        color: @primary-color;
      }
    }

    .mobilePhone {
      font-size: 12px;
      cursor: pointer;
      color: @primary-color;
      font-weight: 700;
      border-bottom: 1px @primary-color solid;
      padding-bottom: 2px;
    }

    .label {
      font-size: 12px;
      background-color: red;
      color: white;
      // color:red;
      border-radius: 2px;
      font-weight: 600;
      margin: 0 10px 5px 0px !important;
      text-shadow: 0px 0px 5px #929292;
      padding: 2px 5px;

    }


  }

}

.leadView {
  background-color: white;
  border: 1px #d3d3d3 solid;
  border-left: hidden;
  margin-right: 1em;
  height: 100%;

  .editBudget {
    display: flex;
  }
}

.popOverBudget {

  .inputNUmber {
    min-width: 150px;
  }

  .btnIcons {
    margin-left: 5px;
  }

}

.purpleBtn {
  background-color: #673ab7;
  border: #673ab7;
}
.contactedBtn {
  background-color: #ff8c00;
  border: #ff8c00;
}

.budgetNe {
  font-size: 14px;
  font-weight: 700;
  padding-right: 10px;
}

.leadViewWrapper {
  // height: ~"calc(100vh - 112px)";
  height: ~"calc(100vh - 112px)";
  // height:100vh;
  //position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}

.drawerCtr {
  padding: 10px
}

.saveBtn {
  margin-bottom: 10px;
}

.overlayArea {
  position: absolute;
  background: gray;
  opacity: 0.9;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 23px;
  color: white;
  font-weight: bold;
  justify-content: center;
  padding-top: 37px;
  text-align: center;
  margin-top: 65px;
}

.radioStyle {
  display: block;
  height: 30px;
  line-Height: 30px;
}


.assignInfo {
  padding: 10px;
  background: white;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #2f6f0f;

  .expired {
    color: red;
    font-weight: bold;
  }

  .assignDate {
    color: @primary-color;
  }

  .hourElapsed {
    color: darkgreen;
  }
}

@primary-color: #6EB042;