@import '~antd/lib/style/themes/default.less';

.customerDetails {
  background: #6eb04224;
  border: 1px solid #6eb0425c;
  display: flex;
  padding: 10px;

  .proposalFor {

    display: flex;
    flex: 1;
    padding-top: 4px;

    .propFor {
      font-size: 14px;
    }

    .customerName {
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
      color: darkslategray;

    }


  }

  .appointBtn {

  }
}

.mobilcustomerDetails {
  background: #6eb04224;
  border: 1px solid #6eb0425c;
  padding: 10px;

  .proposalFor {

    display: flex;
    flex: 1;

    .propFor {
      font-size: 14px;
    }

    .customerName {
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
      color: darkslategray;

    }


  }

  .appointBtn {
  }
}

.projArr {

  button {
    cursor: pointer;
  }

  display: flex;
  margin: 10px 0px;
  border: 3px solid #7883715c;
  background: white;
  border-radius: 7px;
  min-height: 250px;

  .imgDetails {
    text-align: center;
    margin: auto;
    width: 300px;

    .caroImg {
      width: 100%;
      height: 250px;

    }
  }

  .projDetail {
    width: 60%;
    padding: 5px 15px;

    .nameCtr {
      flex-direction: row;
      display: flex;

      .builderImg {
        height: 60px;
      }
    }

    .actionCtr {
      flex-direction: row;
      display: flex;

      .infoCtr {
        width: 43%;

      }

      .variantCtr {
        flex: 1;


        display: flex;
        flex-direction: row;
        overflow-x: scroll;

        .single {
          border: 1px solid @primary-color;
          padding: 5px;
          margin: 0px 5px;

          min-width: 140px;
          //max-height: 90%;
          min-height: 116px;
          border-radius: 6px;

          .variantName {
            font-weight: bold;
            font-size: 12px;
          }


          .variantPrice {
            font-weight: bold;
            font-size: 12px;
          }

          .variantArea {
            font-size: 12px;
          }

          .variantBed {
            display: flex;
            flex-direction: row;

            .bedVal {
              flex: 1;
              font-size: 12px;

              .icontype {
                vertical-align: middle;
              }
            }
          }

          .variantActions {

            margin-top: 5px;
            display: flex;
            flex-direction: row;

            .downBtn {
              background: @primary-color;
              border-width: 0px;
              margin-right: 10px;
              padding: 5px 8px;
              margin-bottom: 5px;


              .downIcon {
                vertical-align: middle;
                height: 17px;

              }
            }
          }
        }
      }

    }

    .name {
      color: @primary-color;
      font-size: 16px;

    }

    .address {

      font-size: 14px;

      .location {
        vertical-align: middle;
        margin-top: -2px;
        font-size: 16px;
      }

    }

    .price {
      font-size: 16px;
      font-weight: bold;

    }

    .typeCtr {
      display: flex;
      margin-top: 8px;

      .type {

        flex: 1;
        padding: 5px 0px;
        font-size: 14px;
        color: #7e817d;



        .typeVal {
          font-size: 14px;
          font-weight: bold;

          .icontype {

            vertical-align: middle;
            font-size: 18px;
            margin-top: -3px;
          }
        }
      }
    }

    .btnCtr {
      display: flex;
      margin-bottom: 5px;

      .wait {
        font-size: 10px;
        margin-bottom: 5px;

      }

      .firstBtn {
        padding-left: 0px;

        .btns {

          border: 0px;
          font-size: 14px;
          background: #6EB042;
          border-radius: 2px;
          color: white;
          padding: 4px 8px;

          .btnIcon {
            vertical-align: middle;
            height: 20px;
            margin-right: 2px;

          }
        }
      }

      div {
        padding: 10px;
      }
    }


  }


  .projMapCtr {
    //width: 100px;
    height: 250px;
    flex: 1

  }
}

.projArrMobile {
  margin: 10px 0px;
  border: 3px solid #7883715c;
  background: white;
  border-radius: 7px;
  min-height: 250px;

  .imgDetails {

    text-align: center;

    .caroImg {
      width: 100%;

    }
  }

  .projDetail {

    padding: 5px 15px;


    .nameCtr {
      flex-direction: row;
      display: flex;

      .builderImg {
        height: 40px;
      }
    }

    .name {
      color: @primary-color;
      font-size: 16px;

    }

    .address {

      font-size: 14px;

      .location {
        vertical-align: middle;
        margin-top: -2px;
        font-size: 16px;
      }

    }

    .price {
      font-size: 16px;
      font-weight: bold;

    }

    .typeCtr {
      display: flex;
      margin-top: 8px;

      .type {

        flex: 1;
        padding: 5px 0px;
        font-size: 13px;
        color: #7e817d;


        .typeVal {
          font-size: 12px;
          font-weight: bold;

          .icontype {

            vertical-align: middle;
            font-size: 18px;
            margin-top: -3px;
          }
        }
      }
    }

    .btnCtr {
      display: flex;
      padding: 5px 0px;

      .wait {
        font-size: 10px;
      }

      .firstBtn {
        padding-left: 0px;

        .btns {
          color: white;
          border: 1px solid @primary-color;
          background: @primary-color;
          padding: 3px 6px;
          border-radius: 2px;
          font-size: 13px;


          .btnIcon {
            vertical-align: middle;
            height: 17px;
            margin-right: 2px;
          }
        }
      }

      div {
        padding: 10px;
      }
    }

    .appointBtn {
      margin-bottom: 10px;

    }

    .variantCtr {
      flex: 1;

      display: flex;
      flex-direction: row;
      overflow-x: scroll;

      margin: 12px 0px;


      .single {
        border: 1px solid @primary-color;
        padding: 5px;
        margin-right: 10px;
        min-width: 140px;
        max-height: 90%;
        border-radius: 6px;

        .variantName {
          font-weight: bold;
          font-size: 12px;
        }


        .variantPrice {
          font-weight: bold;
          font-size: 12px;
        }

        .variantArea {
          font-size: 12px;
        }

        .variantBed {
          display: flex;
          flex-direction: row;

          .bedVal {
            flex: 1;
            font-size: 12px;

            .icontype {
              vertical-align: middle;
            }
          }
        }

        .variantActions {

          margin-top: 5px;
          display: flex;
          flex-direction: row;

          .downBtn {
            background: @primary-color;;
            color: white;
            border-width: 0px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 10px;

            .downIcon {
              vertical-align: middle;
              font-size: 14px;
              height: 25px;
              padding: 5px;
              margin-right: 2px;

            }
          }
        }
      }
    }

  }

  .projMapCtr {
    //width: 100px;
    height: 250px;
    flex: 1
  }
}

.apointIcon {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 10px;
  margin-top: -2px;


}

.downloadingLoad {
  text-align: center;
  flex: 1;
}



@primary-color: #6EB042;