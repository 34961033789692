@import '~antd/lib/style/themes/default.less';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: @layout-body-background;
}
.sitepageheader {
  border: 1px solid rgb(235, 237, 240);
  background-color: #fff;
}
.lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
  :global(.ant-dropdown-trigger) {
    margin-right: 24px;
  }
}

.content {
  padding: 32px 0;
  flex: 1;
}

@media (min-width: @screen-md-min) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
//    background-image: url('https://marketing.bnwdubai.com/static/backgroundMidrif-9465e4cadbd62438f85d4ac4cf71bea1.jpg');
    background-repeat: no-repeat;
  //  background-position: center 110px;
    background-size: 100%;
  }

  .content {
    padding: 32px 0 24px 0;
    background: #faebd754;
    margin: auto;
    width: 500px;
    max-height: 55%;
    margin-top: 20px;
  }
}

.top {
  text-align: center;
  padding-bottom: 30px;
}

.header {
  height: 44px;
  line-height: 44px;
  a {
    text-decoration: none;
  }
}

.logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}

.title {
  font-size: 33px;
  color: @heading-color;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.desc {
  font-size: @font-size-base;
  color: @text-color-secondary;
  margin-top: 12px;
  margin-bottom: 40px;
}

@primary-color: #6EB042;