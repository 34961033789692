.chartTitle {
  text-align: center;
  border-bottom: 2px #646464 solid;
  display: flex;
  margin-bottom: 18px;
}

.boxCol {
  display: flex;
  column-gap: 10px;

  .box {
    width: 180px;
    //height: 0px;
    padding: 20px;
    flex: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 14px;
    margin: 10px 0;

    .title {
      font-size: 36px;
      margin: 0;
      font-weight: 700;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
    }

  }

  .box1 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3019+0,cf0404+100;Red+3D */
    background: #4BB543; /* Old browsers */
    background: -moz-linear-gradient(top, #4BB543 0%, #3a8e35 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4BB543 0%, #3a8e35 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4BB543 0%, #3a8e35 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .box2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3019+0,cf0404+100;Red+3D */
    background: #1a81d9; /* Old browsers */
    background: -moz-linear-gradient(top, #58a8ea 0%, #1a81d9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #58a8ea 0%, #1a81d9 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #58a8ea 0%, #1a81d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .box3 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3019+0,cf0404+100;Red+3D */
    background: #ff3019; /* Old browsers */
    background: -moz-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ff3019 0%, #cf0404 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}



.calanderRow {
  background: white;
  padding: 10px;
  border: 1px #e1e1e1 solid;
  margin-bottom: 11px;
}

.rulesHere {

  background: white;
  height: 100%;
  margin-left: 30px;

  .ruledList {
    color: #555;
    font-size: 12px;
    padding: 0 !important;
    font-family: courier, monospace;
    border: 1px solid #dedede;
    height: 240px;
    overflow-y: scroll;

    li {
      list-style: none;
      border-bottom: 1px dotted #ccc;
      text-indent: 15px;
      text-transform: capitalize;
      padding: 0;
      line-height: 20px;

      &:before {
        content: "\00a0";
        border-left: 1px #bd3434 solid;
        border-right: 1px #bd3434 solid;
        margin-left: 10px;
        margin-right: 10px;
        padding: 4px 0px;
      }

    }

    .followTime {
      font-weight: bold;
    }

    .followText {
      > span {
        padding-left: 20px;
      }

      a {
        text-decoration: underline;
      }
    }

  }

  .dateBatch {
    border: 1px #d5d5d5 solid;
    border-radius: 4px;
    box-shadow: 0px 0px 5px -2px #9f9f9f;
    width: 60px;
    height: 60px;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px 10px 0;

    .date {
      font-size: 24px;
    }

    .month {
      background-color: lighten(@primary-color, 10);
      color: white;
      font-size: 14px;
      width: 100%;
      text-align: center;
      font-weight: bold;

    }
  }

  .followupLabel {
    display: inline-flex;
    flex-direction: column;

    h2, h5 {
      padding: 0;
      margin: 0;
    }

    h5 {
      color: #7d7d7d;
    }
  }


}


.lines {
  border-left: 1px solid #ffaa9f;
  border-right: 1px solid #ffaa9f;
  width: 2px;
  float: left;
  height: 495px;
  margin-left: 40px;
}

@primary-color: #6EB042;