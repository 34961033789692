
.mentionContainer {
  z-index: 3;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid rgba(211, 211, 211, 0.3);
  border-top: none;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 1em;
      border-top: 1px solid rgba(211, 211, 211, 0.3);
      cursor: pointer;
      min-width: 200px;
    }
    li:hover, li.focus {
      background-color: #d6d6d6;
    }
  }
}

.quoteAdd {
  display: flex;
  justify-content: space-between;
}

@primary-color: #6EB042;