@import '~antd/lib/style/themes/default.less';

.amentitiesStyle {
  padding-top: 5px;


}

.icon {

  vertical-align: middle;
}

.btnCtr {
  padding-bottom: 10px;
  flex-direction: row;
}

.selectedProjArea {
  border: 1px solid gainsboro;
  padding: 10px;
  margin-top: 10px;
}

.amenIcon {
  font-size: 18px;
  vertical-align: middle;
}

.listIcon {
  font-size: 16px;
  //margin-right: 10px;
  vertical-align: middle;
}

.btn {

}

.listLoad {
  padding: 2px 10px;
}

.selBtn {
  background: @primary-color;
  padding: 3px 10px;

  border: 1px solid @primary-color;
  color: white;
  cursor: pointer;
  margin-right: 10px;


}

.viewBtnCtr {
  flex-direction: row;
  display: flex;
  justify-content: end;
}

.simpBtn {
  border: 1px solid @primary-color;
  padding: 3px 10px;
  margin-right: 10px;
  align-items: inherit;
  cursor: pointer;
  justify-content: center;
  text-align: center;


}

.choosenFields {
  padding-left: 5px;
}

.whatsApp {
  background: @primary-color;
  color: white;
}

.mailBtn {
  background: @btn-danger-bg;
  color: white;
}

.variationHead {
  margin-bottom: 10px;
  font-size: 16px;
  font-style: italic;
}

.variationName {
  font-size: 14px;
  font-weight: bold;
}

.variationItem {
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  border-bottom: 0.6px solid #eaeaea;
  padding: 12px 0px;

  .priceCtr {
    flex-direction: row;
    display: flex;
    margin: 8px 0px;

    .priceVal {
      padding-left: 10px;

      .priceField {
        font-size: 12px;
        font-weight: 600;
        background-color: lighten(@primary-color, 40%);
        border: 1px lighten(@primary-color, 25%) solid;
        padding: 0px 10px;

      }

      .minPriceField {
        font-size: 12px;
        font-weight: 600;
        background-color: lighten(@orange-10, 40%);
        border: 1px lighten(@orange-10, 25%) solid;
        padding: 0px 10px;
      }
    }

    .areaCtrl {
      margin-left: 10px;
      flex-direction: row;
      display: flex;
    }
  }
}

.contactCtr {
  flex-direction: row;
  display: flex;

  .iconCtrl {
    margin-right: 15px;
  }

  .phoneCtr {
    background: #a6a6a6;
    border-radius: 5px;
    padding: 2px 5px;
    font-weight: 500;
    margin-right: 5px;
    etter-spacing: 1px;
    color: white;
    cursor: pointer;

  }

  .mailCtr {
    background: #a6a6a6;
    border-radius: 5px;
    padding: 2px 5px;
    font-weight: 500;
    margin-right: 5px;
    etter-spacing: 1px;
    color: white;
    cursor: pointer;


  }

  .timeAgo {
    align-self: center;

    font-size: 12px;
    color: #404ced;
    padding-left: 30px;
  }
}

.projectInfoCtr {
  flex-direction: row;
  display: flex;
  margin-top: 8px;

  .campName {
    margin-right: 5px;
    background: #7222b8;
    border-radius: 50%;
    color: #e2e0e0;
    font-size: 13px;
    padding: 2px 5px;

  }

  .proName {

    align-self: center;
  }
}

.projectBack {
  background: red;
}

.projectCtr {
  flex-direction: row;
  display: flex;
  padding: 10px 25px;
  background: #f0fff0;
  border-bottom: 1px solid #d7d7d7;

  .nameCtr {
    //font-weight: 500;
    font-size: 14px;
    flex: 1
  }

  .actionCtr {
    flex-direction: row;
    display: flex;
    padding-left: 20px;

    .actionItem {
      padding-left: 10px;
      font-size: 14px;
      cursor: pointer;

      .actionIcon {

        vertical-align: middle;
        font-size: 12px;
        //margin-right: 10px;
      }

    }
  }

  .variantList {
    list-style: none;

    li {
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .variantName {
        margin-left: 10px;
      }
    }
  }

}

.autoCtr {
  padding: 15px 5px
}

.choosenLead {
  padding: 10px;
  margin-top: 20px;
  border: 2px solid #e9e9e7;

  h2 {
    border-bottom: 1px solid #e9e9e7;
    padding: 10px 0px;
    margin-top: -13px;
  }

  .checkCtr {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    .checkBox {
      padding: 5px;
      font-size: 18px;
    }

  }
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  min-height: 500px;
}

.permissionIcon {

  vertical-align: middle;
  font-size: 16px;
  color: @primary-color;
}

.loadCtrl {
  width: 200px;

  padding-top: 9px;
  padding-left: 10px;

}


.priceField {
  font-size: 12px;
  font-weight: 700;
  background-color: lighten(@primary-color, 40%);
  border: 1px lighten(@primary-color, 25%) solid;
  padding: 3px 10px;

  font-weight: 700;
}

.minPriceField {
  font-size: 12px;
  font-weight: 700;
  background-color: lighten(@orange-10, 40%);
  border: 1px lighten(@orange-10, 25%) solid;
  padding: 3px 10px;
  font-weight: 700;
}

.proposalLeadItem {
  margin-bottom: 10px;
  color: @green-10;
  font-weight: 600;
  font-size: 16px;
}

.filterWrapper {
  padding: 10px 20px;
  background-color: white;
  margin-bottom: 10px;
}

div.mapMarkerCustom {
  height: 20px;
  width: 20px;
  background-color: #ec7a7a;
  position: relative;
  color: #ccc;
  border-radius: 2px;
  text-align: center;
  padding: 2px 2px 0 2px;

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    border: 6px solid transparent;
    position: absolute;
    left: 4px;
    bottom: -12px;
    border-top: 10px solid #ec7a7a;
  }
}


@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}


.myInfoBoxAnimated {
  animation: createBox 0.2s;
  transform-origin: top;
}


.choosenProject {
  padding: 10px;
  border: 2px solid #e9e9e7;
}

.downIcon {
  vertical-align: middle;
  font-size: 22px;
  margin-left: 5px;
}


.colBox {
  margin-left: 10px;
}

.downloading {
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
}

.downloadingVariation {
  font-weight: bold;
  font-size: 14px;
  margin-left: 20px;
}

@primary-color: #6EB042;